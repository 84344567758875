

:root {
	--font-size: 16px;
	--text-color: #444;
	--grey: #777;
	--default-background:#d6d6d6;
	--default-margin: 1.5rem;
	--default-padding: calc(var(--default-margin) / 2);
	--half-margin: calc(var(--default-margin) / 2);
	--quarter-margin: calc(var(--default-margin) / 4);
	
	--blue: rgba(49,116,155,1);
	--red: rgba(240,62,69,1);
	--error: rgba(255,162,169,1);
	--success: rgba(200,255,230,1);
  --green: rgba(100,227,178,1);
	--race-control: rgba(149,216,255,1);

	--object-color: #e0e0e0;
	--border-color: rgba(0,0,0,0.1);
	--item-selected-background: rgba(255,255,255,0.6);
	--item-selected-color: #333;
	
  --hpadding: var(--half-margin);
	--double-pad: var(--default-margin);
	--vpadding: var(--half-margin);

	--object-outer-border-radius: 0.5rem;
	--object-inner-border-radius: 0.35rem;

	}

	html {font: 400 var(--font-size)/1.5em 'Noto Sans',Helvetica,Arial,sans;margin:0;padding:0;color:var(--text-color)}

/* typo */
* {box-sizing: border-box;}
body {margin:0;padding:0;
  background-size: 100% auto;background-repeat: no-repeat;background-position: center center;background-attachment: fixed;}
#root {padding:var(--default-margin);}

main {flex-direction: column;overflow: scroll;}
footer,header {text-align: center;}
header nav {background-color: var(--blue);padding:var(--half-margin);color:white}
header nav img {height:20px;}
header nav ul  {display:flex;margin:0;padding:0;gap: var(--default-margin);align-items: center;flex-wrap: wrap;}
header nav ul li.title {display:flex;align-items: center;gap: var(--quarter-margin);}
header nav ul li {list-style: none;}
header nav ul li a {color:white}
footer {font-size:0.8rem}
h1,h2,h3,h4,h5,h6 {font-weight:600;margin:var(--default-margin) 0;line-height:1.25em;}
h1 em {color:var(--red)}

strong {font-weight:600}

h1 {font-size: 2.25rem;}
h2 {font-size: 1.7rem;}
h3 {font-size: 1.35rem}
h4 {font-size: 1.1rem;}
h6 {font-size: 0.9rem;margin-bottom:0}
h6+p {margin-top:0}
a {color: var(--blue);}
a:hover {cursor: pointer;color:var(--text-color)}


section {width:100%}
.object {border-radius: var(--quarter-margin);padding: var(--default-margin);background-color: var(--green);}
.flex {display:flex;flex-direction: row;gap: var(--half-margin);align-items: flex-start;}
.spacebetween {display:flex;flex-direction: row;justify-content: space-between;align-items: center;gap:var(--default-margin);flex-wrap: wrap;}
.spacearound {display:flex;flex-direction: row;justify-content: center;align-items: center;gap:var(--default-margin);flex-wrap: wrap;}
.scroller {flex-grow: 1;overflow-y: scroll;scrollbar-gutter: 1em;padding-right:0.5rem}
.grow {flex-grow: 1;}

.scroller::-webkit-scrollbar {
  width: 10px;margin:1rem;padding:1rem
}

/* Track */
.scroller::-webkit-scrollbar-track {
  background-color: var(--border-color);
  border-radius: 3px;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  background: var(--blue);
	border-radius: 3px;
}



form,p,ul {margin:var(--default-margin) 0}
label {display:block;text-transform: uppercase;font-weight: 700;font-size: 0.8rem;}

input[type=checkbox] {width:auto;width:1.5rem;height:1.5rem}
input,textarea,select,button {font:inherit;margin:var(--quarter-margin) 0}
button,input[type=submit],input[type=button] {background-color: var(--blue);color:#fff;border:none;
	padding:var(--vpadding) var(--double-pad);border-radius: var(--quarter-margin);}
:is(button,input[type=submit],input[type=button]):hover {filter:brightness(120%)}
:is(button,input[type=submit],input[type=button])[disabled] {background:#555!important}
textarea,select,input:is(:not([type=radio],[type=checkbox],button,input[type=submit],input[type=button])) {background:#fff;
	border: none;padding: var(--vpadding) var(--hpadding);max-width: 100%!important;
	background-color: #ebebeb;border-radius: var(--quarter-margin);vertical-align: top;}
textarea.small, select.small, button.small, input.small:is(:not([type=radio],[type=checkbox],button,input[type=submit],input[type=button]))
	{padding: var(--quarter-margin) var(--half-margin);font-size:85%;}
select {position: relative;padding-right: 2.25rem;
	-webkit-appearance:none;
	background-repeat: no-repeat;
	background-position: center right;
	background-image: url("data:image/svg+xml;utf8,<svg fill='%23666' height='36' viewBox='0 0 24 24' width='36' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
}
textarea {width:100%;min-height:250px}
:is(button,input).cancelButton {background-color: var(--red);}
.switch {line-height: 1em;}

.formError,.formSuccess {padding:var(--default-padding);margin-bottom: var(--default-margin);border-radius: var(--quarter-margin);border: 1px solid var(--border-color);}
.formError {background-color: var(--error)}
.formSuccess {background-color: var(--success)}


.addLapForm form {display: flex;align-items: center;gap:var(--quarter-margin);flex-wrap: wrap;}


.stamp .finalLap {color:var(--green)}
.stamp .lap {color: var(--red)}

.list .time, .list .placering, .list .startnummer {text-align: right;}
.list .klubb, .list .timeDiff {font-style: italic;font-size: 0.9rem;}
.list td {vertical-align: top;}
 

table {border-collapse: collapse;width:100%}
thead {border-bottom: 1px solid var(--border-color);}
tbody tr {border-top: 1px solid var(--border-color);}
td,th {padding:0 var(--quarter-margin);text-align: left;vertical-align: top;}
th {font-weight: 700;}
tbody tr:nth-child(2n+1)  {background-color: rgba(49,116,155,0.05);}
tbody tr:hover {background-color: rgba(49,116,155,0.3);}

.notice {}

.loginForm {
	
	border-radius: var(--default-margin);background-color: var(--green);padding: var(--default-margin);
}
.logo {max-width: 100px;}

.race {display:flex;align-items:center;background-color: var(--object-color);margin: var(--default-margin) 0;flex-wrap: wrap;}
.race .raceStatus {font-size:2rem}
.race .raceInfo {flex:1;}
.race .raceInfo .raceName {text-transform: uppercase;text-decoration: none;font-weight: 500;}
.race .raceInfo .raceDate {text-transform: uppercase;}
.race .raceInfo .raceLocation {text-transform: uppercase;}
.race .raceMenu {display: flex;align-self: flex-end;flex-wrap:wrap}
.race .raceMenu a {text-decoration: none;padding:var(--half-margin) var(--default-margin);color:inherit;position: relative;transition: background-color 0.3s;}
.race .raceMenu a:not(:last-child) {border-right:1px solid var(--border-color)}
.race .raceMenu a:hover,.race .raceMenu a.active {background-color: var(--item-selected-background)}
.race .raceMenu a::after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 0px;width:0%;background-color:transparent;transition: all 0.3s;}
.race .raceMenu a.active::after {width:100%;height:3px;background-color: var(--blue);}

.raceControl {display:flex;justify-content: space-between;padding: var(--quarter-margin) var(--half-margin);
	background-color: var(--race-control);margin: var(--default-margin) 0;gap:var(--half-margin);flex-wrap: wrap;}

.raceClass {display:flex;align-items:center;background-color: var(--object-color);flex-wrap: wrap;
	margin: var(--default-margin) 0;gap:var(--half-margin);padding: var(--quarter-margin) var(--half-margin);}
.raceClass .classInfo {display:grid;flex:1;grid-template-columns: 50% 50%;}
.raceClass .classInfo .className {text-transform: uppercase;text-decoration: none;font-weight: 600;}
.raceClass .classInfo .classStatus {text-transform: uppercase;}
.raceClass .classInfo .startTime {font-weight: 500;}
.raceClass .classInfo .autoStart {text-transform: uppercase;}
.raceClass .classTimer {font-size: 150%;text-transform: uppercase;}
.raceClass .classTimer .timer {display:block;}
.raceClass .classTimer.started {color:var(--red)}
.raceClass .editIcon {margin-left:auto}
.iconBox {display: flex;align-items: center;gap:0.15em;}

.stamp {display: grid;grid-template-columns: minmax(3em,auto) 8ch 1fr 1fr auto; align-items: center;gap:var(--half-margin);border-top:1px solid var(--border-color);}
.stamp.new {animation: pulse-stamp 1s;}
.stamp .bib {font-size: 1.5em;font-weight: 500;justify-self: flex-end;}
.stamp .time {color:var(--blue)}
.stamp .participantInfo {flex:1;}
.stamp .courseName {text-transform: uppercase;}
.stamp .className {text-transform: uppercase;}



.details {display:grid;grid-template-columns: 50% 50%;}
.details .item {font-weight: 500;}
.details .value {}

.tabs .tabBar {background-color: var(--object-color);display:flex;padding:5px;border-radius: var(--object-outer-border-radius);}
.tabs .tabBar .tab {cursor: pointer;padding: var(--half-margin) var(--default-margin);border-radius: var(--object-inner-border-radius);}
.tabs .tabBar .tab.active {background-color: var(--item-selected-background);}
.tabs .tabBar .tab {color:inherit}
.tabs .tabBar .tab.active .tabText {text-decoration:underline;}


table .time:is(th,td) {text-align: right;}
table .number:is(th,td) {text-align: center;}
table .splitPlace {margin:0 0.25rem;font-size:70%;vertical-align: super;}

.raceStartedBlob {
	background: rgba(255, 82, 82, 1);
	box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
	animation: pulse-red 2s infinite;
	border-radius: 50%;
	margin: 7px;
	height: 24px;
	width: 24px;
	transform: scale(1);
}

@keyframes pulse-stamp {
	0% {
		background-color: transparent;
	}
	25% {
		background-color: var(--border-color);
	}
	50% {
		background-color: transparent;
	}
	75% {
		background-color: var(--border-color);
	}
	100% {
		background-color: transparent;
	}

}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@media print {
	header,.raceControl,footer {display:none}
}

@media screen and (max-width: 768px) {
	:root {
		--default-margin: 1rem;
		--font-size: 14px;
	}
	
	body,html {font-size: var(--font-size);}
	.hideOnMobile {display:none}
	
}